import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';

const Titles = (props) => {
  const { className, title, subtitle } = props;

  const ROOT_CLASS = 'service-table__titles';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const TITLE_CLASS = 'service-table__title';
  const SUBTITLE_CLASS = 'service-table__subtitle';

  return (
    <td className={rootClass}>
      <div className={TITLE_CLASS}>{title}</div>
      <div className={SUBTITLE_CLASS}>{subtitle}</div>
    </td>
  );
};

Titles.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Titles.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
};

export default Titles;
