import React from 'react';
import VipIcon from '../components/years-packages/VipIcon';
import StandardIcon from '../components/years-packages/StandardIcon';
import PremiumIcon from '../components/years-packages/PremiumIcon';

export default [
  {
    title: 'V.I.P',
    theme: 'yellow',
    icon: <VipIcon />,
    contents: [
      '- 12 стрижек (комплекс)',
      '- 5 УЗ чисток зубов',
      '- Набор вкусняшек на год',
    ],
    items: [
      {
        title: '8 000 ₽',
        text: 'Для декоративных пород ',
      },
      {
        title: '10 000 ₽',
        text: 'Для средних пород',
      },
      {
        title: '12 000 ₽',
        text: 'Для больших пород',
      },
    ],
  },
  {
    title: 'Премиум',
    theme: 'purple',
    icon: <PremiumIcon />,
    contents: [
      '- 12 стрижек (комплекс)',
      '- 5 УЗ чисток зубов',
    ],
    items: [
      {
        title: '8 000 ₽',
        text: 'Для декоративных пород ',
      },
      {
        title: '10 000 ₽',
        text: 'Для средних пород',
      },
      {
        title: '12 000 ₽',
        text: 'Для больших пород',
      },
    ],
  },
  {
    title: 'Стандарт',
    theme: 'red',
    icon: <StandardIcon />,
    contents: [
      '- 12 стрижек (комплекс)',
    ],
    items: [
      {
        title: '8 000 ₽',
        text: 'Для декоративных пород ',
      },
      {
        title: '10 000 ₽',
        text: 'Для средних пород',
      },
      {
        title: '12 000 ₽',
        text: 'Для больших пород',
      },
    ],
  },
];
