import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
  const { className, href } = props;

  return (
    <span className={className}>
      <svg>
        <use xlinkHref={`#${href}`} />
      </svg>
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
