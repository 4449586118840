export default [
  {
    id: 1,
    serviceNameId: 1,
    breedId: 1,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 2,
    serviceNameId: 2,
    breedId: 1,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 3,
    serviceNameId: 3,
    breedId: 1,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 4,
    serviceNameId: 4,
    breedId: 1,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 5,
    serviceNameId: 5,
    breedId: 1,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 6,
    serviceNameId: 6,
    breedId: 1,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 7,
    serviceNameId: 7,
    breedId: 1,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 8,
    serviceNameId: 8,
    breedId: 1,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 9,
    serviceNameId: 9,
    breedId: 1,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 10,
    serviceNameId: 1,
    breedId: 2,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 11,
    serviceNameId: 2,
    breedId: 2,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 12,
    serviceNameId: 3,
    breedId: 2,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 13,
    serviceNameId: 4,
    breedId: 2,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 14,
    serviceNameId: 5,
    breedId: 2,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 15,
    serviceNameId: 6,
    breedId: 2,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 16,
    serviceNameId: 7,
    breedId: 2,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 17,
    serviceNameId: 8,
    breedId: 2,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 18,
    serviceNameId: 9,
    breedId: 2,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 19,
    serviceNameId: 1,
    breedId: 3,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 20,
    serviceNameId: 2,
    breedId: 3,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 21,
    serviceNameId: 3,
    breedId: 3,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 22,
    serviceNameId: 4,
    breedId: 3,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 23,
    serviceNameId: 5,
    breedId: 3,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 24,
    serviceNameId: 6,
    breedId: 3,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 25,
    serviceNameId: 7,
    breedId: 3,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 26,
    serviceNameId: 8,
    breedId: 3,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 27,
    serviceNameId: 9,
    breedId: 3,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 28,
    serviceNameId: 1,
    breedId: 4,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 29,
    serviceNameId: 2,
    breedId: 4,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 30,
    serviceNameId: 3,
    breedId: 4,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 31,
    serviceNameId: 4,
    breedId: 4,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 32,
    serviceNameId: 5,
    breedId: 4,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 33,
    serviceNameId: 6,
    breedId: 4,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 34,
    serviceNameId: 7,
    breedId: 4,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 35,
    serviceNameId: 8,
    breedId: 4,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 36,
    serviceNameId: 9,
    breedId: 4,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 37,
    serviceNameId: 1,
    breedId: 5,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 38,
    serviceNameId: 2,
    breedId: 5,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 39,
    serviceNameId: 3,
    breedId: 5,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 40,
    serviceNameId: 4,
    breedId: 5,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 41,
    serviceNameId: 5,
    breedId: 5,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 42,
    serviceNameId: 6,
    breedId: 5,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 43,
    serviceNameId: 7,
    breedId: 5,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 44,
    serviceNameId: 8,
    breedId: 5,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 45,
    serviceNameId: 9,
    breedId: 5,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 46,
    serviceNameId: 1,
    breedId: 6,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 47,
    serviceNameId: 2,
    breedId: 6,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 48,
    serviceNameId: 3,
    breedId: 6,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 49,
    serviceNameId: 4,
    breedId: 6,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 50,
    serviceNameId: 5,
    breedId: 6,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 51,
    serviceNameId: 6,
    breedId: 6,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 52,
    serviceNameId: 7,
    breedId: 6,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 53,
    serviceNameId: 8,
    breedId: 6,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 54,
    serviceNameId: 9,
    breedId: 6,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 55,
    serviceNameId: 1,
    breedId: 7,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 56,
    serviceNameId: 2,
    breedId: 7,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 57,
    serviceNameId: 3,
    breedId: 7,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 58,
    serviceNameId: 4,
    breedId: 7,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 59,
    serviceNameId: 5,
    breedId: 7,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 60,
    serviceNameId: 6,
    breedId: 7,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 61,
    serviceNameId: 7,
    breedId: 7,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 62,
    serviceNameId: 8,
    breedId: 7,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 63,
    serviceNameId: 9,
    breedId: 7,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 64,
    serviceNameId: 1,
    breedId: 8,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 65,
    serviceNameId: 2,
    breedId: 8,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 66,
    serviceNameId: 3,
    breedId: 8,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 67,
    serviceNameId: 4,
    breedId: 8,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 68,
    serviceNameId: 5,
    breedId: 8,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 69,
    serviceNameId: 6,
    breedId: 8,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 70,
    serviceNameId: 7,
    breedId: 8,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 71,
    serviceNameId: 8,
    breedId: 8,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 72,
    serviceNameId: 9,
    breedId: 8,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 73,
    serviceNameId: 1,
    breedId: 9,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 74,
    serviceNameId: 2,
    breedId: 9,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 75,
    serviceNameId: 3,
    breedId: 9,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 76,
    serviceNameId: 4,
    breedId: 9,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 77,
    serviceNameId: 5,
    breedId: 9,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 78,
    serviceNameId: 6,
    breedId: 9,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 79,
    serviceNameId: 7,
    breedId: 9,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 80,
    serviceNameId: 8,
    breedId: 9,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 81,
    serviceNameId: 9,
    breedId: 9,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 82,
    serviceNameId: 1,
    breedId: 10,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 83,
    serviceNameId: 2,
    breedId: 10,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 84,
    serviceNameId: 3,
    breedId: 10,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 85,
    serviceNameId: 4,
    breedId: 10,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 86,
    serviceNameId: 5,
    breedId: 10,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 87,
    serviceNameId: 6,
    breedId: 10,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 88,
    serviceNameId: 7,
    breedId: 10,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 89,
    serviceNameId: 8,
    breedId: 10,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 90,
    serviceNameId: 9,
    breedId: 10,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 91,
    serviceNameId: 1,
    breedId: 11,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 92,
    serviceNameId: 2,
    breedId: 11,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 93,
    serviceNameId: 3,
    breedId: 11,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 94,
    serviceNameId: 4,
    breedId: 11,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 95,
    serviceNameId: 5,
    breedId: 11,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 96,
    serviceNameId: 6,
    breedId: 11,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 97,
    serviceNameId: 7,
    breedId: 11,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 98,
    serviceNameId: 8,
    breedId: 11,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 99,
    serviceNameId: 9,
    breedId: 11,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 100,
    serviceNameId: 1,
    breedId: 12,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 101,
    serviceNameId: 2,
    breedId: 12,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 102,
    serviceNameId: 3,
    breedId: 12,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 103,
    serviceNameId: 4,
    breedId: 12,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 104,
    serviceNameId: 5,
    breedId: 12,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 105,
    serviceNameId: 6,
    breedId: 12,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 106,
    serviceNameId: 7,
    breedId: 12,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 107,
    serviceNameId: 8,
    breedId: 12,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 108,
    serviceNameId: 9,
    breedId: 12,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 109,
    serviceNameId: 1,
    breedId: 13,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 110,
    serviceNameId: 2,
    breedId: 13,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 111,
    serviceNameId: 3,
    breedId: 13,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 112,
    serviceNameId: 4,
    breedId: 13,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 113,
    serviceNameId: 5,
    breedId: 13,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 114,
    serviceNameId: 6,
    breedId: 13,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 115,
    serviceNameId: 7,
    breedId: 13,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 116,
    serviceNameId: 8,
    breedId: 13,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 117,
    serviceNameId: 9,
    breedId: 13,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 118,
    serviceNameId: 1,
    breedId: 14,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 119,
    serviceNameId: 2,
    breedId: 14,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 120,
    serviceNameId: 3,
    breedId: 14,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 121,
    serviceNameId: 4,
    breedId: 14,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 122,
    serviceNameId: 5,
    breedId: 14,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 123,
    serviceNameId: 6,
    breedId: 14,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 124,
    serviceNameId: 7,
    breedId: 14,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 125,
    serviceNameId: 8,
    breedId: 14,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 126,
    serviceNameId: 9,
    breedId: 14,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 127,
    serviceNameId: 1,
    breedId: 15,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 128,
    serviceNameId: 2,
    breedId: 15,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 129,
    serviceNameId: 3,
    breedId: 15,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 130,
    serviceNameId: 4,
    breedId: 15,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 131,
    serviceNameId: 5,
    breedId: 15,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 132,
    serviceNameId: 6,
    breedId: 15,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 133,
    serviceNameId: 7,
    breedId: 15,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 134,
    serviceNameId: 8,
    breedId: 15,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 135,
    serviceNameId: 9,
    breedId: 15,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 136,
    serviceNameId: 1,
    breedId: 16,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 137,
    serviceNameId: 2,
    breedId: 16,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 138,
    serviceNameId: 3,
    breedId: 16,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 139,
    serviceNameId: 4,
    breedId: 16,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 140,
    serviceNameId: 5,
    breedId: 16,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 141,
    serviceNameId: 6,
    breedId: 16,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 142,
    serviceNameId: 7,
    breedId: 16,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 143,
    serviceNameId: 8,
    breedId: 16,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 144,
    serviceNameId: 9,
    breedId: 16,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 145,
    serviceNameId: 1,
    breedId: 17,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 146,
    serviceNameId: 2,
    breedId: 17,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 147,
    serviceNameId: 3,
    breedId: 17,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 148,
    serviceNameId: 4,
    breedId: 17,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 149,
    serviceNameId: 5,
    breedId: 17,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 150,
    serviceNameId: 6,
    breedId: 17,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 151,
    serviceNameId: 7,
    breedId: 17,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 152,
    serviceNameId: 8,
    breedId: 17,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 153,
    serviceNameId: 9,
    breedId: 17,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 154,
    serviceNameId: 1,
    breedId: 18,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 155,
    serviceNameId: 2,
    breedId: 18,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 156,
    serviceNameId: 3,
    breedId: 18,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 157,
    serviceNameId: 4,
    breedId: 18,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 158,
    serviceNameId: 5,
    breedId: 18,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 159,
    serviceNameId: 6,
    breedId: 18,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 160,
    serviceNameId: 7,
    breedId: 18,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 161,
    serviceNameId: 8,
    breedId: 18,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 162,
    serviceNameId: 9,
    breedId: 18,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 163,
    serviceNameId: 1,
    breedId: 19,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 164,
    serviceNameId: 2,
    breedId: 19,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 165,
    serviceNameId: 3,
    breedId: 19,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 166,
    serviceNameId: 4,
    breedId: 19,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 167,
    serviceNameId: 5,
    breedId: 19,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 168,
    serviceNameId: 6,
    breedId: 19,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 169,
    serviceNameId: 7,
    breedId: 19,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 170,
    serviceNameId: 8,
    breedId: 19,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 171,
    serviceNameId: 9,
    breedId: 19,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 172,
    serviceNameId: 1,
    breedId: 20,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 173,
    serviceNameId: 2,
    breedId: 20,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 174,
    serviceNameId: 3,
    breedId: 20,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 175,
    serviceNameId: 4,
    breedId: 20,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 176,
    serviceNameId: 5,
    breedId: 20,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 177,
    serviceNameId: 6,
    breedId: 20,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 178,
    serviceNameId: 7,
    breedId: 20,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 179,
    serviceNameId: 8,
    breedId: 20,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 180,
    serviceNameId: 9,
    breedId: 20,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 181,
    serviceNameId: 1,
    breedId: 21,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 182,
    serviceNameId: 2,
    breedId: 21,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 183,
    serviceNameId: 3,
    breedId: 21,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 184,
    serviceNameId: 4,
    breedId: 21,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 185,
    serviceNameId: 5,
    breedId: 21,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 186,
    serviceNameId: 6,
    breedId: 21,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 187,
    serviceNameId: 7,
    breedId: 21,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 188,
    serviceNameId: 8,
    breedId: 21,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 189,
    serviceNameId: 9,
    breedId: 21,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 190,
    serviceNameId: 1,
    breedId: 22,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 191,
    serviceNameId: 2,
    breedId: 22,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 192,
    serviceNameId: 3,
    breedId: 22,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 193,
    serviceNameId: 4,
    breedId: 22,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 194,
    serviceNameId: 5,
    breedId: 22,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 195,
    serviceNameId: 6,
    breedId: 22,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 196,
    serviceNameId: 7,
    breedId: 22,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 197,
    serviceNameId: 8,
    breedId: 22,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 198,
    serviceNameId: 9,
    breedId: 22,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 199,
    serviceNameId: 1,
    breedId: 23,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 200,
    serviceNameId: 2,
    breedId: 23,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 201,
    serviceNameId: 3,
    breedId: 23,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 202,
    serviceNameId: 4,
    breedId: 23,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 203,
    serviceNameId: 5,
    breedId: 23,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 204,
    serviceNameId: 6,
    breedId: 23,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 205,
    serviceNameId: 7,
    breedId: 23,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 206,
    serviceNameId: 8,
    breedId: 23,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 207,
    serviceNameId: 9,
    breedId: 23,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 208,
    serviceNameId: 1,
    breedId: 24,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 209,
    serviceNameId: 2,
    breedId: 24,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 210,
    serviceNameId: 3,
    breedId: 24,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 211,
    serviceNameId: 4,
    breedId: 24,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 212,
    serviceNameId: 5,
    breedId: 24,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 213,
    serviceNameId: 6,
    breedId: 24,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 214,
    serviceNameId: 7,
    breedId: 24,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 215,
    serviceNameId: 8,
    breedId: 24,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 216,
    serviceNameId: 9,
    breedId: 24,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 217,
    serviceNameId: 1,
    breedId: 25,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 218,
    serviceNameId: 2,
    breedId: 25,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 219,
    serviceNameId: 3,
    breedId: 25,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 220,
    serviceNameId: 4,
    breedId: 25,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 221,
    serviceNameId: 5,
    breedId: 25,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 222,
    serviceNameId: 6,
    breedId: 25,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 223,
    serviceNameId: 7,
    breedId: 25,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 224,
    serviceNameId: 8,
    breedId: 25,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 225,
    serviceNameId: 9,
    breedId: 25,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 226,
    serviceNameId: 1,
    breedId: 26,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 227,
    serviceNameId: 2,
    breedId: 26,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 228,
    serviceNameId: 3,
    breedId: 26,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 229,
    serviceNameId: 4,
    breedId: 26,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 230,
    serviceNameId: 5,
    breedId: 26,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 231,
    serviceNameId: 6,
    breedId: 26,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 232,
    serviceNameId: 7,
    breedId: 26,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 233,
    serviceNameId: 8,
    breedId: 26,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 234,
    serviceNameId: 9,
    breedId: 26,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 235,
    serviceNameId: 1,
    breedId: 27,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 236,
    serviceNameId: 2,
    breedId: 27,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 237,
    serviceNameId: 3,
    breedId: 27,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 238,
    serviceNameId: 4,
    breedId: 27,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 239,
    serviceNameId: 5,
    breedId: 27,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 240,
    serviceNameId: 6,
    breedId: 27,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 241,
    serviceNameId: 7,
    breedId: 27,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 242,
    serviceNameId: 8,
    breedId: 27,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 243,
    serviceNameId: 9,
    breedId: 27,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 244,
    serviceNameId: 1,
    breedId: 28,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 245,
    serviceNameId: 2,
    breedId: 28,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 246,
    serviceNameId: 3,
    breedId: 28,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 247,
    serviceNameId: 4,
    breedId: 28,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 248,
    serviceNameId: 5,
    breedId: 28,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 249,
    serviceNameId: 6,
    breedId: 28,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 250,
    serviceNameId: 7,
    breedId: 28,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 251,
    serviceNameId: 8,
    breedId: 28,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 252,
    serviceNameId: 9,
    breedId: 28,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 253,
    serviceNameId: 1,
    breedId: 29,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 254,
    serviceNameId: 2,
    breedId: 29,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 255,
    serviceNameId: 3,
    breedId: 29,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 256,
    serviceNameId: 4,
    breedId: 29,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 257,
    serviceNameId: 5,
    breedId: 29,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 258,
    serviceNameId: 6,
    breedId: 29,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 259,
    serviceNameId: 7,
    breedId: 29,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 260,
    serviceNameId: 8,
    breedId: 29,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 261,
    serviceNameId: 9,
    breedId: 29,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 262,
    serviceNameId: 1,
    breedId: 30,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 263,
    serviceNameId: 2,
    breedId: 30,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 264,
    serviceNameId: 3,
    breedId: 30,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 265,
    serviceNameId: 4,
    breedId: 30,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 266,
    serviceNameId: 5,
    breedId: 30,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 267,
    serviceNameId: 6,
    breedId: 30,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 268,
    serviceNameId: 7,
    breedId: 30,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 269,
    serviceNameId: 8,
    breedId: 30,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 270,
    serviceNameId: 9,
    breedId: 30,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 271,
    serviceNameId: 1,
    breedId: 31,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 272,
    serviceNameId: 2,
    breedId: 31,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 273,
    serviceNameId: 3,
    breedId: 31,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 274,
    serviceNameId: 4,
    breedId: 31,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 275,
    serviceNameId: 5,
    breedId: 31,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 276,
    serviceNameId: 6,
    breedId: 31,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 277,
    serviceNameId: 7,
    breedId: 31,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 278,
    serviceNameId: 8,
    breedId: 31,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 279,
    serviceNameId: 9,
    breedId: 31,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 280,
    serviceNameId: 1,
    breedId: 32,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 281,
    serviceNameId: 2,
    breedId: 32,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 282,
    serviceNameId: 3,
    breedId: 32,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 283,
    serviceNameId: 4,
    breedId: 32,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 284,
    serviceNameId: 5,
    breedId: 32,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 285,
    serviceNameId: 6,
    breedId: 32,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 286,
    serviceNameId: 7,
    breedId: 32,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 287,
    serviceNameId: 8,
    breedId: 32,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 288,
    serviceNameId: 9,
    breedId: 32,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 289,
    serviceNameId: 1,
    breedId: 33,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 290,
    serviceNameId: 2,
    breedId: 33,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 291,
    serviceNameId: 3,
    breedId: 33,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 292,
    serviceNameId: 4,
    breedId: 33,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 293,
    serviceNameId: 5,
    breedId: 33,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 294,
    serviceNameId: 6,
    breedId: 33,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 295,
    serviceNameId: 7,
    breedId: 33,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 296,
    serviceNameId: 8,
    breedId: 33,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 297,
    serviceNameId: 9,
    breedId: 33,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 298,
    serviceNameId: 1,
    breedId: 34,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 299,
    serviceNameId: 2,
    breedId: 34,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 300,
    serviceNameId: 3,
    breedId: 34,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 301,
    serviceNameId: 4,
    breedId: 34,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 302,
    serviceNameId: 5,
    breedId: 34,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 303,
    serviceNameId: 6,
    breedId: 34,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 304,
    serviceNameId: 7,
    breedId: 34,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 305,
    serviceNameId: 8,
    breedId: 34,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 306,
    serviceNameId: 9,
    breedId: 34,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 307,
    serviceNameId: 1,
    breedId: 35,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 308,
    serviceNameId: 2,
    breedId: 35,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 309,
    serviceNameId: 3,
    breedId: 35,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 310,
    serviceNameId: 4,
    breedId: 35,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 311,
    serviceNameId: 5,
    breedId: 35,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 312,
    serviceNameId: 6,
    breedId: 35,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 313,
    serviceNameId: 7,
    breedId: 35,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 314,
    serviceNameId: 8,
    breedId: 35,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 315,
    serviceNameId: 9,
    breedId: 35,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 316,
    serviceNameId: 1,
    breedId: 36,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 317,
    serviceNameId: 2,
    breedId: 36,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 318,
    serviceNameId: 3,
    breedId: 36,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 319,
    serviceNameId: 4,
    breedId: 36,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 320,
    serviceNameId: 5,
    breedId: 36,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 321,
    serviceNameId: 6,
    breedId: 36,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 322,
    serviceNameId: 7,
    breedId: 36,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 323,
    serviceNameId: 8,
    breedId: 36,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 324,
    serviceNameId: 9,
    breedId: 36,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 325,
    serviceNameId: 1,
    breedId: 37,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 326,
    serviceNameId: 2,
    breedId: 37,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 327,
    serviceNameId: 3,
    breedId: 37,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 328,
    serviceNameId: 4,
    breedId: 37,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 329,
    serviceNameId: 5,
    breedId: 37,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 330,
    serviceNameId: 6,
    breedId: 37,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 331,
    serviceNameId: 7,
    breedId: 37,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 332,
    serviceNameId: 8,
    breedId: 37,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 333,
    serviceNameId: 9,
    breedId: 37,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 334,
    serviceNameId: 1,
    breedId: 38,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 335,
    serviceNameId: 2,
    breedId: 38,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 336,
    serviceNameId: 3,
    breedId: 38,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 337,
    serviceNameId: 4,
    breedId: 38,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 338,
    serviceNameId: 5,
    breedId: 38,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 339,
    serviceNameId: 6,
    breedId: 38,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 340,
    serviceNameId: 7,
    breedId: 38,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 341,
    serviceNameId: 8,
    breedId: 38,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 342,
    serviceNameId: 9,
    breedId: 38,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 343,
    serviceNameId: 1,
    breedId: 39,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 344,
    serviceNameId: 2,
    breedId: 39,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 345,
    serviceNameId: 3,
    breedId: 39,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 346,
    serviceNameId: 4,
    breedId: 39,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 347,
    serviceNameId: 5,
    breedId: 39,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 348,
    serviceNameId: 6,
    breedId: 39,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 349,
    serviceNameId: 7,
    breedId: 39,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 350,
    serviceNameId: 8,
    breedId: 39,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 351,
    serviceNameId: 9,
    breedId: 39,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 352,
    serviceNameId: 1,
    breedId: 40,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 353,
    serviceNameId: 2,
    breedId: 40,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 354,
    serviceNameId: 3,
    breedId: 40,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 355,
    serviceNameId: 4,
    breedId: 40,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 356,
    serviceNameId: 5,
    breedId: 40,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 357,
    serviceNameId: 6,
    breedId: 40,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 358,
    serviceNameId: 7,
    breedId: 40,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 359,
    serviceNameId: 8,
    breedId: 40,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 360,
    serviceNameId: 9,
    breedId: 40,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 361,
    serviceNameId: 1,
    breedId: 41,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 362,
    serviceNameId: 2,
    breedId: 41,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 363,
    serviceNameId: 3,
    breedId: 41,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 364,
    serviceNameId: 4,
    breedId: 41,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 365,
    serviceNameId: 5,
    breedId: 41,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 366,
    serviceNameId: 6,
    breedId: 41,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 367,
    serviceNameId: 7,
    breedId: 41,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 368,
    serviceNameId: 8,
    breedId: 41,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 369,
    serviceNameId: 9,
    breedId: 41,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 370,
    serviceNameId: 1,
    breedId: 42,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 371,
    serviceNameId: 2,
    breedId: 42,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 372,
    serviceNameId: 3,
    breedId: 42,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 373,
    serviceNameId: 4,
    breedId: 42,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 374,
    serviceNameId: 5,
    breedId: 42,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 375,
    serviceNameId: 6,
    breedId: 42,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 376,
    serviceNameId: 7,
    breedId: 42,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 377,
    serviceNameId: 8,
    breedId: 42,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 378,
    serviceNameId: 9,
    breedId: 42,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  {
    id: 379,
    serviceNameId: 1,
    breedId: 43,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 290000,
  },
  {
    id: 380,
    serviceNameId: 2,
    breedId: 43,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 50000,
  },
  {
    id: 381,
    serviceNameId: 3,
    breedId: 43,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 18000,
  },
  {
    id: 382,
    serviceNameId: 4,
    breedId: 43,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 50000,
  },
  {
    id: 383,
    serviceNameId: 5,
    breedId: 43,
    timeText: 'Примерно 30 мин',
    time: 30,
    price: 90000,
  },
  {
    id: 384,
    serviceNameId: 6,
    breedId: 43,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 45000,
  },
  {
    id: 385,
    serviceNameId: 7,
    breedId: 43,
    timeText: '',
    time: null,
    price: 100000,
  },
  {
    id: 386,
    serviceNameId: 8,
    breedId: 43,
    timeText: 'Примерно 10 мин',
    time: 10,
    price: 50000,
  },
  {
    id: 387,
    serviceNameId: 9,
    breedId: 43,
    timeText: 'Примерно 15 мин',
    time: 15,
    price: 15000,
  },
  // {
  //   id: ,
  //   serviceNameId: 1,
  //   breedId: ,
  //   timeText: 'Примерно 30 мин',
  //   time: 30,
  //   price: 290000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 2,
  //   breedId: ,
  //   timeText: 'Примерно 30 мин',
  //   time: 30,
  //   price: 50000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 3,
  //   breedId: ,
  //   timeText: 'Примерно 30 мин',
  //   time: 30,
  //   price: 18000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 4,
  //   breedId: ,
  //   timeText: 'Примерно 15 мин',
  //   time: 15,
  //   price: 50000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 5,
  //   breedId: ,
  //   timeText: 'Примерно 30 мин',
  //   time: 30,
  //   price: 90000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 6,
  //   breedId: ,
  //   timeText: 'Примерно 15 мин',
  //   time: 15,
  //   price: 45000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 7,
  //   breedId: ,
  //   timeText: '',
  //   time: null,
  //   price: 100000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 8,
  //   breedId: ,
  //   timeText: 'Примерно 10 мин',
  //   time: 10,
  //   price: 50000,
  // },
  // {
  //   id: ,
  //   serviceNameId: 9,
  //   breedId: ,
  //   timeText: 'Примерно 15 мин',
  //   time: 15,
  //   price: 15000,
  // },
];
