import React from 'react';
import PropTypes from 'prop-types';

const ROOT_CLASS = 'years-packages-list__content';
const TITLE_CLASS = 'years-packages-list__content-title';
const LIST_CLASS = 'years-packages-list__content-list';
const ITEM_CLASS = 'years-packages-list__content-item';

const Content = ({ items }) => (
  <div className={ROOT_CLASS}>
    <div className={TITLE_CLASS}>Содержание пакета</div>
    <ul className={LIST_CLASS}>
      {items.map((item, key) => <li className={ITEM_CLASS} key={key}>{item}</li>)}
    </ul>
  </div>
);

Content.propTypes = {
  items: PropTypes.array,
};

Content.defaultProps = {
  items: [],
};

export default Content;
