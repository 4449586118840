import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import OfferCard from '../offer-card/offer-card';
import './years-package.scss';

const YearsPackage = (props) => {
  const {
    className, title, items, icon, theme,
  } = props;
  const ROOT_CLASS = 'years-package';
  const WRAP_CLASS = `${ROOT_CLASS}__wrap`;
  const TITLE_CLASS = `${ROOT_CLASS}__title`;
  const LIST_CLASS = `${ROOT_CLASS}__list`;

  // todo alex вынести в Item
  const ITEM_CLASS = `${ROOT_CLASS}__item`;
  const ITEM_TITLE_CLASS = `${ROOT_CLASS}__item-title`;
  const ITEM_TEXT_CLASS = `${ROOT_CLASS}__item-text`;
  const ICON_CLASS = `${ROOT_CLASS}__icon`;
  const rootClass = mergeClasses(className, ROOT_CLASS);

  return (
    <OfferCard className={rootClass} wrapClass={WRAP_CLASS} title={title} titleClass={TITLE_CLASS} theme={theme}>
      <ul className={LIST_CLASS}>
        {items.map((item, key) => (
          <li className={ITEM_CLASS} key={key}>
            <div className={ITEM_TITLE_CLASS}>{item.title}</div>
            <div className={ITEM_TEXT_CLASS}>{item.text}</div>
          </li>
        ))}
      </ul>
      <div className={ICON_CLASS}>{icon}</div>
    </OfferCard>
  );
};

YearsPackage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  icon: PropTypes.node,
  theme: PropTypes.string,
};

YearsPackage.defaultProps = {
  className: '',
  title: '',
  items: [],
  icon: null,
  theme: '',
};

export default YearsPackage;
