import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';

const Container = (props) => {
  const { className, children } = props;

  const ROOT_CLASS = 'container';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return <div className={rootClass}>{children}</div>;
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  className: '',
};

export default Container;
