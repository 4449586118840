import React from 'react';
import PropTypes from 'prop-types';
import YearsPackage from '../years-package/years-package';
import './years-packages-list.scss';
import { mergeClasses } from '../../../libs/helpers';
import Content from './content';
import PackageButton from './button';

const YearsPackageList = (props) => {
  const {
    className, itemClass, packageClass, items, isShowContents, buttonWrapClass, isShowButton,
  } = props;

  const ROOT_CLASS = 'years-packages-list';
  const rootClass = mergeClasses(className, ROOT_CLASS);

  const ITEM_CLASS = 'years-packages-list__li';
  const itemClassName = mergeClasses(itemClass, ITEM_CLASS);

  const PACKAGE_CLASS = 'years-packages-list__package';
  const packageClassName = mergeClasses(packageClass, PACKAGE_CLASS);

  const BUTTON_WRAP_CLASS = 'years-packages-list__button-wrap';
  const buttonWrapClassName = mergeClasses(buttonWrapClass, BUTTON_WRAP_CLASS);

  return (
    <ul className={rootClass}>
      {items.map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={itemClassName} key={key}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <YearsPackage className={packageClassName} {...item} />
          {isShowContents && <Content items={item.contents} />}
          {isShowButton && <PackageButton className={buttonWrapClassName} href="/" />}
        </li>
      ))}
    </ul>
  );
};

YearsPackageList.propTypes = {
  className: PropTypes.string,
  itemClass: PropTypes.string,
  packageClass: PropTypes.string,
  items: PropTypes.array,
  isShowContents: PropTypes.bool,
  buttonWrapClass: PropTypes.string,
  isShowButton: PropTypes.bool,
};

YearsPackageList.defaultProps = {
  className: '',
  itemClass: '',
  packageClass: '',
  items: [],
  isShowContents: false,
  buttonWrapClass: '',
  isShowButton: false,
};

export default YearsPackageList;
