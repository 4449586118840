export default [
  {
    id: 1,
    name: 'Австралийский шелковистый терьер',
    sizeId: 1,
  },
  {
    id: 2,
    name: 'Английский бульдог',
    sizeId: 1,
  },
  {
    id: 3,
    name: 'Аффен-пинчер',
    sizeId: 1,
  },
  {
    id: 4,
    name: 'Бивер йоркширский терьер',
    sizeId: 1,
  },
  {
    id: 5,
    name: 'Биро йоркширский терьер',
    sizeId: 1,
  },
  {
    id: 6,
    name: 'Бишон фризе',
    sizeId: 1,
  },
  {
    id: 7,
    name: 'Болонка',
    sizeId: 1,
  },
  {
    id: 8,
    name: 'Болоньез',
    sizeId: 1,
  },
  {
    id: 9,
    name: 'Бостон терьер',
    sizeId: 1,
  },
  {
    id: 10,
    name: 'Вельш-Корги',
    sizeId: 1,
  },
  {
    id: 11,
    name: 'Вольпино итальяно',
    sizeId: 1,
  },
  {
    id: 12,
    name: 'Грифон брюсселький',
    sizeId: 1,
  },
  {
    id: 13,
    name: 'Гриффон пти брабансон',
    sizeId: 1,
  },
  {
    id: 14,
    name: 'Йоркширский терьер',
    sizeId: 1,
  },
  {
    id: 15,
    name: 'Китайская хохлатая голая',
    sizeId: 1,
  },
  {
    id: 16,
    name: 'Китайская хохлатая пуховка',
    sizeId: 1,
  },
  {
    id: 17,
    name: 'Котон де тулеар',
    sizeId: 1,
  },
  {
    id: 18,
    name: 'Левретка',
    sizeId: 1,
  },
  {
    id: 19,
    name: 'Левхен',
    sizeId: 1,
  },
  {
    id: 20,
    name: 'Лхасский апсо',
    sizeId: 1,
  },
  {
    id: 21,
    name: 'Мальтийская болонка',
    sizeId: 1,
  },
  {
    id: 22,
    name: 'Мальтипу',
    sizeId: 1,
  },
  {
    id: 23,
    name: 'Мопс',
    sizeId: 1,
  },
  {
    id: 24,
    name: 'Московский дракон',
    sizeId: 1,
  },
  {
    id: 25,
    name: 'Папильон (Континентальный той-спаниель)',
    sizeId: 1,
  },
  {
    id: 26,
    name: 'Пекинес',
    sizeId: 1,
  },
  {
    id: 27,
    name: 'Петербургская орхидея',
    sizeId: 1,
  },
  {
    id: 28,
    name: 'Помски',
    sizeId: 1,
  },
  {
    id: 29,
    name: 'Русская салонная',
    sizeId: 1,
  },
  {
    id: 30,
    name: 'Той терьер гладкошерстный',
    sizeId: 1,
  },
  {
    id: 31,
    name: 'Той терьер русский гладкошерстный',
    sizeId: 1,
  },
  {
    id: 32,
    name: 'Той терьер русский длинношерстный',
    sizeId: 1,
  },
  {
    id: 33,
    name: 'Французский бульдог',
    sizeId: 1,
  },
  {
    id: 34,
    name: 'Чихуахуа гладкошерстная',
    sizeId: 1,
  },
  {
    id: 35,
    name: 'Чихуахуа длинношерстная',
    sizeId: 1,
  },
  {
    id: 36,
    name: 'Ши-тцу',
    sizeId: 1,
  },
  {
    id: 37,
    name: 'Шпиц большой (> 38 см)',
    sizeId: 1,
  },
  {
    id: 38,
    name: 'Шпиц малый (26 см +/- 3)',
    sizeId: 1,
  },
  {
    id: 39,
    name: 'Шпиц померанский (20 см +/- 2)',
    sizeId: 1,
  },
  {
    id: 40,
    name: 'Шпиц средний (34 см +/- 4)',
    sizeId: 1,
  },
  {
    id: 41,
    name: 'Шпиц японский',
    sizeId: 1,
  },
  {
    id: 42,
    name: 'Японский хин',
    sizeId: 1,
  },
  {
    id: 43,
    name: 'Другие породы до 5 кг',
    sizeId: 1,
  },
  {
    id: 44,
    name: 'Американский водяной спаниель',
    sizeId: 2,
  },
  {
    id: 45,
    name: 'Американский кокер-спаниель',
    sizeId: 2,
  },
  {
    id: 46,
    name: 'Английский кокер-спаниель',
    sizeId: 2,
  },
  {
    id: 47,
    name: 'Английский сеттер',
    sizeId: 2,
  },
  {
    id: 48,
    name: 'Английский спрингер-спаниель',
    sizeId: 2,
  },
  {
    id: 49,
    name: 'Бедлингтон-терьер',
    sizeId: 2,
  },
  {
    id: 50,
    name: 'Бигль',
    sizeId: 2,
  },
  {
    id: 51,
    name: 'Вельш терьер',
    sizeId: 2,
  },
  {
    id: 52,
    name: 'Вест хайленд уайт терьер',
    sizeId: 2,
  },
  {
    id: 53,
    name: 'Денди-динмонт-терьер',
    sizeId: 2,
  },
  {
    id: 54,
    name: 'Джек-рассел терьер гладкий',
    sizeId: 2,
  },
  {
    id: 55,
    name: 'Джек-рассел терьер жесткошерстный',
    sizeId: 2,
  },
  {
    id: 56,
    name: 'Ирландский водяной спаниель',
    sizeId: 2,
  },
  {
    id: 57,
    name: 'Ирландский сеттер',
    sizeId: 2,
  },
  {
    id: 58,
    name: 'Кавалер кинг-чарльз-спаниель',
    sizeId: 2,
  },
  {
    id: 59,
    name: 'Керн терьер',
    sizeId: 2,
  },
  {
    id: 60,
    name: 'Керри-блю-терьер',
    sizeId: 2,
  },
  {
    id: 61,
    name: 'Кламбер-спаниель',
    sizeId: 2,
  },
  {
    id: 62,
    name: 'Кокер-спаниель',
    sizeId: 2,
  },
  {
    id: 63,
    name: 'Лабрадудль',
    sizeId: 2,
  },
  {
    id: 64,
    name: 'Норвич терьер',
    sizeId: 2,
  },
  {
    id: 65,
    name: 'Норфолк терьер',
    sizeId: 2,
  },
  {
    id: 66,
    name: 'Парсон рассел терьер',
    sizeId: 2,
  },
  {
    id: 67,
    name: 'Пудель Карликовый',
    sizeId: 2,
  },
  {
    id: 68,
    name: 'Пудель Королевский большой',
    sizeId: 2,
  },
  {
    id: 69,
    name: 'Пудель Малый',
    sizeId: 2,
  },
  {
    id: 70,
    name: 'Пудель Той',
    sizeId: 2,
  },
  {
    id: 71,
    name: 'Пудель-пойтнер',
    sizeId: 2,
  },
  {
    id: 72,
    name: 'Пшеничный терьер',
    sizeId: 2,
  },
  {
    id: 73,
    name: 'Русский спаниель',
    sizeId: 2,
  },
  {
    id: 74,
    name: 'Сиба-ину',
    sizeId: 2,
  },
  {
    id: 75,
    name: 'Силихем-терьер',
    sizeId: 2,
  },
  {
    id: 76,
    name: 'Скайтерьер',
    sizeId: 2,
  },
  {
    id: 77,
    name: 'Скотч-терьер',
    sizeId: 2,
  },
  {
    id: 78,
    name: 'Средний шнауцер (миттельшнауцер)',
    sizeId: 2,
  },
  {
    id: 79,
    name: 'Такса гладкошерстная',
    sizeId: 2,
  },
  {
    id: 80,
    name: 'Такса длинношерстная',
    sizeId: 2,
  },
  {
    id: 81,
    name: 'Такса карликовая',
    sizeId: 2,
  },
  {
    id: 82,
    name: 'Такса кроличья',
    sizeId: 2,
  },
  {
    id: 83,
    name: 'Такси жесткошерсная',
    sizeId: 2,
  },
  {
    id: 84,
    name: 'Тибетский терьер',
    sizeId: 2,
  },
  {
    id: 85,
    name: 'Уиппет',
    sizeId: 2,
  },
  {
    id: 86,
    name: 'Фокстерьер гладкошерстный',
    sizeId: 2,
  },
  {
    id: 87,
    name: 'Фокстерьер жесткошерстный',
    sizeId: 2,
  },
  {
    id: 88,
    name: 'Цвергшнауцер',
    sizeId: 2,
  },
  {
    id: 89,
    name: 'Чешский терьер',
    sizeId: 2,
  },
  {
    id: 90,
    name: 'Шотландский сеттер',
    sizeId: 2,
  },
  {
    id: 91,
    name: 'Ягдтерьер',
    sizeId: 2,
  },
  {
    id: 92,
    name: 'Другие породы от 5 до 10 кг',
    sizeId: 2,
  },
  {
    id: 93,
    name: 'Другие породы от 10 до 20 кг',
    sizeId: 2,
  },
  {
    id: 94,
    name: 'Австралийская овчарка',
    sizeId: 3,
  },
  {
    id: 95,
    name: 'Акита-ину',
    sizeId: 3,
  },
  {
    id: 96,
    name: 'Аляскинский маламут',
    sizeId: 3,
  },
  {
    id: 97,
    name: 'Американский бульдог',
    sizeId: 3,
  },
  {
    id: 98,
    name: 'Афганская борзая',
    sizeId: 3,
  },
  {
    id: 99,
    name: 'Бассет-Хаунд',
    sizeId: 3,
  },
  {
    id: 100,
    name: 'Бернский зенненхунд',
    sizeId: 3,
  },
  {
    id: 101,
    name: 'Бобтейл',
    sizeId: 3,
  },
  {
    id: 102,
    name: 'Бордоский дог',
    sizeId: 3,
  },
  {
    id: 103,
    name: 'Бульмастиф',
    sizeId: 3,
  },
  {
    id: 104,
    name: 'Вольф-шпиц',
    sizeId: 3,
  },
  {
    id: 105,
    name: 'Восточно-европейская овчарка',
    sizeId: 3,
  },
  {
    id: 106,
    name: 'Голден ретривер',
    sizeId: 3,
  },
  {
    id: 107,
    name: 'Грюнендаль',
    sizeId: 3,
  },
  {
    id: 108,
    name: 'Доберман',
    sizeId: 3,
  },
  {
    id: 109,
    name: 'Ирландский волкодав',
    sizeId: 3,
  },
  {
    id: 110,
    name: 'Кавказская овчарка',
    sizeId: 3,
  },
  {
    id: 111,
    name: 'Кане корсо',
    sizeId: 3,
  },
  {
    id: 112,
    name: 'Колли',
    sizeId: 3,
  },
  {
    id: 113,
    name: 'Лабрадор',
    sizeId: 3,
  },
  {
    id: 114,
    name: 'Лайка',
    sizeId: 3,
  },
  {
    id: 115,
    name: 'Леонбергер',
    sizeId: 3,
  },
  {
    id: 116,
    name: 'Московская сторожевая',
    sizeId: 3,
  },
  {
    id: 117,
    name: 'Немецкая овчарка',
    sizeId: 3,
  },
  {
    id: 118,
    name: 'Немецкий дог',
    sizeId: 3,
  },
  {
    id: 119,
    name: 'Ньюфаундленд',
    sizeId: 3,
  },
  {
    id: 120,
    name: 'Пиренейская горная собака',
    sizeId: 3,
  },
  {
    id: 121,
    name: 'Прямошерстный ретривер',
    sizeId: 3,
  },
  {
    id: 122,
    name: 'Риджбек тайский родезийский',
    sizeId: 3,
  },
  {
    id: 123,
    name: 'Ризеншнауцер',
    sizeId: 3,
  },
  {
    id: 124,
    name: 'Русская псовая борзая',
    sizeId: 3,
  },
  {
    id: 125,
    name: 'Русский черный терьер',
    sizeId: 3,
  },
  {
    id: 126,
    name: 'Самоед',
    sizeId: 3,
  },
  {
    id: 127,
    name: 'Самоедская лайка',
    sizeId: 3,
  },
  {
    id: 128,
    name: 'Сенбернар',
    sizeId: 3,
  },
  {
    id: 129,
    name: 'Среднеазиатская овчарка (Алабай)',
    sizeId: 3,
  },
  {
    id: 130,
    name: 'Фландрский бувье',
    sizeId: 3,
  },
  {
    id: 131,
    name: 'Хаски',
    sizeId: 3,
  },
  {
    id: 132,
    name: 'Чау-чау',
    sizeId: 3,
  },
  {
    id: 133,
    name: 'Шарпей',
    sizeId: 3,
  },
  {
    id: 134,
    name: 'Эрдельтерьер',
    sizeId: 3,
  },
  {
    id: 135,
    name: 'Другие породы от 20 до 40 кг',
    sizeId: 3,
  },
  {
    id: 136,
    name: 'Другие породы более 40 кг',
    sizeId: 3,
  },
];
