import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import ServiceTableRow from './row';
import './service-table.scss';

const ServiceTable = (props) => {
  const { className, items } = props;
  const ROOT_CLASS = 'service-table';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <table className={rootClass}>
      <tbody>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {items.map((item, key) => <ServiceTableRow {...item} key={key} />)}
      </tbody>
    </table>
  );
};

ServiceTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
};

ServiceTable.defaultProps = {
  className: '',
  items: [],
};

export default ServiceTable;
