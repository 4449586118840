import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import LinkButton from '../buttons/link-button/link-button';

const BUTTON_CLASS = 'years-packages-list__button';

const PackageButton = ({ className, href }) => {
  const ROOT_CLASS = 'years-packages-list__button-wrap';
  const rootClass = mergeClasses(className, ROOT_CLASS);

  return (
    <div className={rootClass}>
      <LinkButton
        className={BUTTON_CLASS}
        to={href}
        outline
        theme="black"
      >
        Купить
      </LinkButton>
    </div>
  );
};

PackageButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
};

PackageButton.defaultProps = {
  className: 'className',
};

export default PackageButton;
