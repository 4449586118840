import React from 'react';
import PropTypes from 'prop-types';
import ServiceIcon from '../service-icon/service-icon';
import { mergeClasses } from '../../../libs/helpers';

const Icon = (props) => {
  const { className, href, bgColor } = props;

  const ROOT_CLASS = 'service-table__icon';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const ICON_WRAP_CLASS = `${ROOT_CLASS}-wrap`;

  return (
    <td className={rootClass}>
      <ServiceIcon className={ICON_WRAP_CLASS} href={href} bgColor={bgColor} />
    </td>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
};


Icon.defaultProps = {
  className: '',
  bgColor: 'orchid',
};

export default Icon;
