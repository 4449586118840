import React from 'react';
import PropTypes from 'prop-types';
import { Clock } from '../icons/icons';
import { mergeClasses } from '../../../libs/helpers';

const Time = (props) => {
  const { className, text } = props;

  const ROOT_CLASS = 'service-table__time';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const TIME_WRAP_CLASS = `${ROOT_CLASS}-wrap`;
  const TIME_ICON_CLASS = `${ROOT_CLASS}-icon`;
  const TIME_TEXT_CLASS = `${ROOT_CLASS}-text`;

  return (
    <td className={rootClass}>
      <div className={TIME_WRAP_CLASS}>
        {text && (
          <>
            <Clock className={TIME_ICON_CLASS} />
            <div className={TIME_TEXT_CLASS}>{text}</div>
          </>
        )}
      </div>
    </td>
  );
};

Time.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

Time.defaultProps = {
  className: '',
  text: '',
};

export default Time;
