import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from '../../autosuggest/autosuggest';
import './autosuggest.scss';
import breeds from '../../../__mocks__/architecture/breeds';

const emptyResult = {
  id: 'all',
  name: 'Другая порода',
  render: <span className="services-page__autosuggest-empty">Другая порода</span>,
};

const breedItems = [...breeds, { ...emptyResult }];

const ServicesPageAutosuggest = (props) => {
  const { onSelected, onClear, value } = props;

  const ROOT_CLASS = 'services-page__autosuggest';

  const [valueSearch, setSearchValue] = useState(value);

  const handleChangeInput = (event, { newValue }) => {
    setSearchValue(newValue);
  };

  return (
    <Autosuggest
      className={ROOT_CLASS}
      placeholder="Например, йоркширский терьер"
      value={valueSearch}
      onChange={handleChangeInput}
      items={breedItems}
      onSelected={onSelected}
      emptyResult={[emptyResult]}
      onClear={onClear}
    />
  );
};

ServicesPageAutosuggest.propTypes = {
  onSelected: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.string,
};

ServicesPageAutosuggest.defaultProps = {
  onSelected: () => {
  },
  onClear: () => {
  },
  value: '',
};

export default ServicesPageAutosuggest;
