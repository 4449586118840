import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import Titles from './titles';
import Icon from './icon';
import Time from './time';
import Price from './price';

const ServiceTableRow = (props) => {
  const {
    className, iconHref, theme, name, description, time, price, priceDescription,
  } = props;

  const ROOT_CLASS = 'service-table';

  const ROW_CLASS = `${ROOT_CLASS}__row`;
  const rowClass = mergeClasses(ROW_CLASS, className);

  const COL_CLASS = `${ROOT_CLASS}__col`;

  return (
    <tr className={rowClass}>
      <Icon className={COL_CLASS} href={iconHref} bgColor={theme} />
      <Titles
        className={COL_CLASS}
        title={name}
        subtitle={description}
      />
      <Time className={COL_CLASS} text={time} />
      <Price className={COL_CLASS} title={price} caption={priceDescription} />
    </tr>
  );
};

ServiceTableRow.propTypes = {
  className: PropTypes.string,
  iconHref: PropTypes.string,
  theme: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
  price: PropTypes.string,
  priceDescription: PropTypes.string,
};

ServiceTableRow.defaultProps = {
  className: '',
  iconHref: 'services-icon-13',
  theme: 'orchid',
  name: '',
  description: '',
  time: '',
  price: '',
  priceDescription: '',
};

export default ServiceTableRow;
