import hygiene from './complexes/hygiene';
import haircut from './complexes/haircut';
import hygieneHaircut from './complexes/hygiene_haircut';
import hygieneTrimming from './complexes/hygiene_trimming';
import bathingDrying from './complexes/bathing_drying';

export default [
  hygiene,
  haircut,
  hygieneHaircut,
  hygieneTrimming,
  bathingDrying,
  hygieneHaircut,
  haircut,
];
