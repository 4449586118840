import React from 'react';
import PropTypes from 'prop-types';
import OfferSection from '../offer-section/offer-section';
import ComplexesList from '../complexes-list/complexes-list';
import './complexes-section.scss';
import { mergeClasses } from '../../../libs/helpers';
import items from '../../__mocks__/complexes';

const description = 'Для вашего удобства мы собрали готовые комплексы, которые включают в себя самые популярные услуги.'
  + ' Комплекс всегда дешевле, чем услуги по отдельности.';

const ComplexesSection = (props) => {
  const { className } = props;

  const ROOT_CLASS = 'complexes-section';
  const rootClass = mergeClasses(className, ROOT_CLASS);

  const BODY_CLASS = `${ROOT_CLASS}__body`;
  const WRAP_CLASS = `${ROOT_CLASS}__wrap`;
  const LIST_CLASS = `${ROOT_CLASS}__list`;
  const ITEM_CLASS = `${ROOT_CLASS}__li`;
  const COMPLEX_CLASS = `${ROOT_CLASS}__complex`;

  return (
    <OfferSection
      className={rootClass}
      bodyClass={BODY_CLASS}
      title="Комплексы"
      description={description}
    >
      <div className={WRAP_CLASS}>
        <ComplexesList className={LIST_CLASS} itemClass={ITEM_CLASS} complexClass={COMPLEX_CLASS} items={items} />
      </div>
    </OfferSection>
  );
};

ComplexesSection.propTypes = {
  className: PropTypes.string,
};

ComplexesSection.defaultProps = {
  className: 'className',
};

export default ComplexesSection;
