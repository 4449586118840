import React, { useState } from 'react';
import Layout from '../components/layout';
import Container from '../components/container/container';
import ComplexesSection from '../components/complexes-section/complexes-section';
import YearsPackages from '../components/years-packages/years-packages';
import Autosuggest from '../components/services-page/autosuggest/autosuggest';
import '../components/services-page/services-page.scss';
import Table from '../components/service-table/service-table';
import servicesData from '../__mocks__/architecture/services';
import servicesNames from '../__mocks__/architecture/services-names';

const pageName = 'services-page';

const getServicesByBreedId = (id) => servicesData.filter((item) => item.breedId === id);
const getServiceName = (id) => servicesNames.find((item) => item.id === id);

const getServiceInfo = (service) => {
  const serviceData = getServiceName(service.serviceNameId);

  const { price, timeText } = service;
  const formatPrice = price / 100;

  return {
    ...serviceData,
    time: timeText,
    price: `${formatPrice} ₽`,
  };
};

const getServicesInfo = (services) => services.map((item) => getServiceInfo(item));

const ServicesPage = () => {
  const [breedId, setBreedId] = useState(null);
  const [items, setItems] = useState([]);

  const handleSelected = ({ id }) => {
    setBreedId(id);
    const services = getServicesByBreedId(id);
    setItems(getServicesInfo(services));
  };

  const handleClear = () => {
    setBreedId(null);
  };

  const mainClass = `${pageName}__main`;
  const FS_CLASS = `${pageName}__fs`;
  const TITLE_CLASS = `${pageName}__title page__title page__title_h1 page__title_standard-page`;
  const SUBTITLE_CLASS = `${pageName}__subtitle page__title page__title_h2`;
  const SERVICE_TABLE_CONTAINER_CLASS = `${pageName}__service-table-container`;
  const COMPLEXES_SECTION_CLASS = `${pageName}__complexes-section`;
  const YEARS_PACKAGES_CLASS = `${pageName}__years-packages`;
  const title = 'Услуги';
  const subtitle = 'Введи название породы, чтобы узнать стоимость услуг';

  return (
    <Layout pageName={pageName} mainClass={mainClass} headerTheme="yellow">
      <div className={FS_CLASS}>
        <Container>
          <h1 className={TITLE_CLASS}>{title}</h1>
          <h2 className={SUBTITLE_CLASS}>{subtitle}</h2>
          <Autosuggest onSelected={handleSelected} onClear={handleClear} />
        </Container>
      </div>
      <Container className={SERVICE_TABLE_CONTAINER_CLASS}>
        {items && <Table items={items} breedId={breedId} />}
      </Container>
      <Container>
        <ComplexesSection className={COMPLEXES_SECTION_CLASS} />
        <YearsPackages className={YEARS_PACKAGES_CLASS} />
      </Container>
    </Layout>
  );
};

export default ServicesPage;
