import desktopIcon from '../../images/complexes/hygiene_trimming.svg';
import mobileIcon from '../../images/complexes/hygiene_trimming_mobile.svg';

export default {
  title: 'Гигиена + тримминг',
  theme: 'yellow',
  discount: '-15%',
  iconSvgClass: 'hygiene_trimming',
  desktopIcon,
  mobileIcon,
};
