import desktopIcon from '../../images/complexes/hygiene_haircut.svg';
import mobileIcon from '../../images/complexes/hygiene_haircut_mobile.svg';

export default {
  title: 'Гигиена + стрижка',
  theme: 'red',
  discount: '-12%',
  iconSvgClass: 'hygiene_haircut',
  desktopIcon,
  mobileIcon,
};
