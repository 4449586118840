import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ComplexCard from '../complex-card/complex-card';
import './complexes-list.scss';
import { mergeClasses } from '../../../libs/helpers';

const ComplexesList = (props) => {
  const {
    className, itemClass, complexClass, items,
  } = props;

  const ROOT_CLASS = 'complexes-list';
  const rootClass = mergeClasses(className, ROOT_CLASS);

  const ITEM_CLASS = `${ROOT_CLASS}__li`;
  const itemClassName = mergeClasses(itemClass, ITEM_CLASS);

  const COMPLEX_CLASS = `${ROOT_CLASS}__complex`;
  const complexClassName = mergeClasses(complexClass, COMPLEX_CLASS);

  const LINK_CLASS = `${ROOT_CLASS}__link`;

  return (
    <ul className={rootClass}>
      {items.map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={itemClassName} key={key}>
          <Link to="/" className={LINK_CLASS}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <ComplexCard className={complexClassName} {...item} />
          </Link>
        </li>
      ))}
    </ul>
  );
};

ComplexesList.propTypes = {
  className: PropTypes.string,
  itemClass: PropTypes.string,
  complexClass: PropTypes.string,
  items: PropTypes.array,
  isShowContents: PropTypes.bool,
  buttonWrapClass: PropTypes.string,
  isShowButton: PropTypes.bool,
};

ComplexesList.defaultProps = {
  className: '',
  itemClass: '',
  complexClass: '',
  items: [],
  isShowContents: false,
  buttonWrapClass: '',
  isShowButton: false,
};

export default ComplexesList;
