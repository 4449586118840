import React from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '../../../libs/useWindowSize';
import { mergeClasses } from '../../../libs/helpers';
import OfferCard from '../offer-card/offer-card';
import Icon from './ComplexIcon';
import './complex-card.scss';

const ComplexCard = (props) => {
  const {
    className, title, desktopIcon, mobileIcon, theme, discount, iconSvgClass,
  } = props;
  const ROOT_CLASS = 'complex-card';
  const WRAP_CLASS = `${ROOT_CLASS}__wrap`;
  const TITLE_CLASS = `${ROOT_CLASS}__title`;
  const BOTTOM_CLASS = `${ROOT_CLASS}__bottom`;
  const ICON_CLASS = `${ROOT_CLASS}__icon`;

  const rootClass = mergeClasses(className, ROOT_CLASS);
  const iconSvgClassName = mergeClasses(ICON_CLASS, `${ICON_CLASS}_${iconSvgClass}`);

  const [width] = useWindowSize();
  const iconId = width < 576 ? (mobileIcon || desktopIcon).id : desktopIcon.id;

  return (
    <OfferCard className={rootClass} wrapClass={WRAP_CLASS} title={title} titleClass={TITLE_CLASS} theme={theme}>
      {discount && <div className={BOTTOM_CLASS}>{discount}</div>}
      {desktopIcon && <Icon className={iconSvgClassName} href={iconId} />}
    </OfferCard>
  );
};

ComplexCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  desktopIcon: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  mobileIcon: PropTypes.object,
  theme: PropTypes.string,
  discount: PropTypes.string,
  iconSvgClass: PropTypes.string,
};

ComplexCard.defaultProps = {
  className: '',
  title: '',
  desktopIcon: null,
  mobileIcon: null,
  theme: 'yellow',
  discount: '',
  iconSvgClass: '',
};

export default ComplexCard;
