import React from 'react';
import PropTypes from 'prop-types';
import { SimpleArrow } from '../icons/icons';
import items from '../../__mocks__/years-packages';
import OfferSection from '../offer-section/offer-section';
import './years-packages.scss';
import LinkButton from '../buttons/link-button/link-button';
import { mergeClasses } from '../../../libs/helpers';
import YearsPackageList from '../years-packages-list/years-packages-list';

const YearsPackages = (props) => {
  const { className } = props;

  const Icon = SimpleArrow.right;

  const ROOT_CLASS = 'years-packages';
  const rootClass = mergeClasses(className, ROOT_CLASS);

  const BUTTON_WRAP_CLASS = 'years-packages__button-wrap';
  const BUTTON_CLASS = 'years-packages__button';
  const LIST_CLASS = 'years-packages__list';
  const ITEM_CLASS = 'years-packages__li';
  const PACKAGE_CLASS = 'years-packages__package';

  const ButtonSection = (
    <LinkButton
      to="/years-packages"
      className={BUTTON_CLASS}
      theme="black"
      outline
      iconRight={<Icon />}
    >
      Сравнить
    </LinkButton>
  );

  return (
    <OfferSection
      className={rootClass}
      title="Выгодные годовые пакеты услуг"
      description="TeziGroming предлагает вам пакеты самых популярных услуг на весь год с выгодой до 20%."
      button={ButtonSection}
      buttonWrapClass={BUTTON_WRAP_CLASS}
    >
      <YearsPackageList className={LIST_CLASS} itemClass={ITEM_CLASS} packageClass={PACKAGE_CLASS} items={items} />
    </OfferSection>
  );
};

YearsPackages.propTypes = {
  className: PropTypes.string,
};

YearsPackages.defaultProps = {
  className: 'className',
};

export default YearsPackages;
