export default [
  {
    id: 1,
    name: 'Ультразвуковая чистка зубов',
    description: 'Щёткой Phillips Sonicare',
    priceDescription: 'За 1 клык',
  },
  {
    id: 2,
    name: 'Чистка зубов пастой',
    description: 'Пастой «Лесной бальзам»',
    priceDescription: 'За 1 клык',
  },
  {
    id: 3,
    name: 'Снятие зубного камня / налета механически',
    description: 'Механически',
    priceDescription: 'За 1 клык',
  },
  {
    id: 4,
    name: 'Лапакюр',
    description: 'Стрижка + шлифовка когтей',
    priceDescription: '',
  },
  {
    id: 5,
    name: 'Гигиена',
    description: 'уши, глаза, когти',
    priceDescription: '',
  },
  {
    id: 6,
    name: 'Чистка параанальных желез',
    description: '',
    priceDescription: '',
  },
  {
    id: 7,
    name: 'Вычесывание колтунов/линяющей шерсти/сбривание колтунов',
    description: '',
    priceDescription: 'За час',
  },
  {
    id: 8,
    name: 'Антипаразитарный комплекс (дополнительно к услугам)',
    description: '',
    priceDescription: '',
  },
  {
    id: 9,
    name: 'Снятие клеща',
    description: '',
    priceDescription: '',
  },
  {
    id: 10,
    name: 'Плетение косичек',
    description: '',
    priceDescription: '',
  },
  {
    id: 11,
    name: 'Блеск Тату',
    description: '',
    priceDescription: '',
  },
  {
    id: 12,
    name: 'Покрытие когтей лаком',
    description: '',
    priceDescription: '',
  },
  {
    id: 13,
    name: 'Декор стразами (при заказе комплекса)',
    description: '',
    priceDescription: '',
  },
  {
    id: 14,
    name: 'Шиншила',
    description: '',
    priceDescription: '',
  },
  {
    id: 15,
    name: 'Ананас',
    description: '',
    priceDescription: '',
  },
  {
    id: 16,
    name: 'Тигр и Леопард',
    description: 'До 30 см',
    priceDescription: '',
  },
  {
    id: 17,
    name: 'Тигр и Леопард',
    description: 'От 31 до 45 см',
    priceDescription: '',
  },
  {
    id: 18,
    name: 'Медвежонок панда',
    description: 'До 30 см',
    priceDescription: '',
  },
  {
    id: 19,
    name: 'Медвежонок панда',
    description: 'От 31 до 45 см',
    priceDescription: '',
  },
  {
    id: 20,
    name: 'Зебра',
    description: 'До 30 см',
    priceDescription: '',
  },
  {
    id: 21,
    name: 'Зебра',
    description: 'От 31 до 45 см',
    priceDescription: '',
  },
  {
    id: 22,
    name: 'Леопард на натуральной шерсти',
    description: 'До 30 см',
    priceDescription: '',
  },
  {
    id: 23,
    name: 'Леопард на натуральной шерсти',
    description: 'От 31 до 45 см',
    priceDescription: '',
  },
  {
    id: 24,
    name: 'Леопард на окрашеном фоне',
    description: 'До 30 см',
    priceDescription: '',
  },
  {
    id: 25,
    name: 'Леопард на окрашеном фоне',
    description: 'От 31 до 45 см',
    priceDescription: '',
  },
  {
    id: 26,
    name: '2 лапы (носок)',
    description: '',
    priceDescription: '',
  },
  {
    id: 27,
    name: '4 лапы (носок)',
    description: '',
    priceDescription: '',
  },
  {
    id: 28,
    name: 'Хвост кончик',
    description: '',
    priceDescription: '',
  },
  {
    id: 29,
    name: 'Хвост целиком',
    description: '',
    priceDescription: '',
  },
  {
    id: 30,
    name: 'Топ-нот (голова) целиком',
    description: '',
    priceDescription: '',
  },
  {
    id: 31,
    name: 'Мордочка окантовка',
    description: '',
    priceDescription: '',
  },
  {
    id: 32,
    name: 'Уши 2 уха',
    description: '',
    priceDescription: '',
  },
  {
    id: 33,
    name: 'Юбка',
    description: '',
    priceDescription: '',
  },
  {
    id: 34,
    name: '3D рисунок',
    description: '',
    priceDescription: '',
  },
  {
    id: 35,
    name: '2 цвета и более ГРАДИЕНТ',
    description: '',
    priceDescription: '',
  },
  {
    id: 36,
    name: '2 цвета и более Многослойное окрашивание',
    description: 'Стоимость мытья и сушки по породе',
    priceDescription: '',
  },
  {
    id: 37,
    name: 'Мини трафарет',
    description: '',
    priceDescription: '',
  },
  {
    id: 38,
    name: 'Хвост',
    description: '',
    priceDescription: '',
  },
  {
    id: 39,
    name: 'Уши',
    description: '',
    priceDescription: '',
  },
  {
    id: 40,
    name: 'Лапки',
    description: '',
    priceDescription: '',
  },
  {
    id: 41,
    name: '"Енот"',
    description: '',
    priceDescription: '',
  },
  {
    id: 42,
    name: 'Сеанс озонотерапии с маской',
    description: '',
    priceDescription: '',
  },
  {
    id: 43,
    name: 'Сеанс озонотерапии без маски',
    description: '',
    priceDescription: '',
  },
  {
    id: 44,
    name: 'Нанесение маски Special One для шерсти и кожи во время мытья',
    description: '',
    priceDescription: '',
  },
  {
    id: 45,
    name: 'Агрессивность животного (на усмотрение мастера) ',
    description: '',
    priceDescription: '',
  },
  {
    id: 46,
    name: 'Полный тримминг (ручная щипка) ',
    description: '',
    priceDescription: '',
  },
  {
    id: 47,
    name: 'Коррекция тримминга',
    description: '',
    priceDescription: '',
  },
  {
    id: 48,
    name: 'Лапакюр (стрижка + шлифовка когтей)',
    description: '',
    priceDescription: '',
  },
  {
    id: 49,
    name: 'Чистка ушей с лосьоном',
    description: '',
    priceDescription: '',
  },
  {
    id: 50,
    name: 'Чистка глаз с лосьоном',
    description: '',
    priceDescription: '',
  },
  {
    id: 51,
    name: 'Стрижка ушей',
    description: '',
    priceDescription: '',
  },
  {
    id: 52,
    name: 'Стрижка лап',
    description: '',
    priceDescription: '',
  },
  {
    id: 53,
    name: 'Стрижка паха',
    description: '',
    priceDescription: '',
  },
  {
    id: 54,
    name: 'Стрижка головы',
    description: '',
    priceDescription: '',
  },
  {
    id: 55,
    name: 'Чистка анальных желез',
    description: '',
    priceDescription: '',
  },
  {
    id: 56,
    name: 'Ультразвуковая чистка зубов',
    description: '',
    priceDescription: '',
  },
  {
    id: 57,
    name: 'Чистка зубов пастой',
    description: '',
    priceDescription: '',
  },
  {
    id: 58,
    name: 'Снятие зубного камня / налета механически',
    description: '',
    priceDescription: '',
  },
  {
    id: 59,
    name: 'Вычесывание колтунов/линяющей шерсти / сбривание колтунов',
    description: '',
    priceDescription: '',
  },
  {
    id: 60,
    name: 'Антипаразитарный комплекс (дополнительно к услугам)',
    description: '',
    priceDescription: '',
  },
  {
    id: 61,
    name: 'Снятие 1 клеща, обработка раны',
    description: '',
    priceDescription: '',
  },
  {
    id: 62,
    name: 'Стрижка "Лев"',
    description: 'полный комплекс + модельная стрижка',
    priceDescription: '',
  },
  {
    id: 63,
    name: 'Гигиеническая стрижка',
    description: 'без мытья',
    priceDescription: '',
  },
  {
    id: 64,
    name: 'Купание',
    description: 'гигиенический комплекс',
    priceDescription: '',
  },
  {
    id: 65,
    name: 'Мягкие коготки',
    description: '',
    priceDescription: '',
  },
  {
    id: 66,
    name: 'Стрижка когтей',
    description: '',
    priceDescription: '',
  },
  {
    id: 67,
    name: 'Вычесывание колтунов',
    description: '',
    priceDescription: '',
  },
  {
    id: 68,
    name: 'SPA-уход',
    description: '',
    priceDescription: '',
  },
  {
    id: 69,
    name: 'Антипаразитарный комплекс',
    description: '',
    priceDescription: '',
  },
  {
    id: 70,
    name: 'Мытье сухим шампунем',
    description: '',
    priceDescription: '',
  },
  {
    id: 71,
    name: 'Чистка зубов щеткой с зубной пастой',
    description: 'с инструктажем для вашего удобства и последующей продажей',
    priceDescription: '',
  },
  {
    id: 72,
    name: 'Агрессивность животного',
    description: '(на усмотрение мастера)',
    priceDescription: '',
  },
  {
    id: 73,
    name: 'Мейн-кун',
    description: '',
    priceDescription: '',
  },
  {
    id: 74,
    name: 'Обезжиривание пастой',
    description: '',
    priceDescription: '',
  },
  {
    id: 75,
    name: 'Ультразвуковая чистка зубов',
    description: '',
    priceDescription: '',
  },
  {
    id: 76,
    name: 'Обработка раны',
    description: 'Перекисью водорода 3% раствора',
    priceDescription: 'За 1 рану',
  },
];
