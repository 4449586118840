import React, { useState } from 'react';
import ReactAutosuggest from 'react-autosuggest';
import Highlight from 'react-highlighter';
import PropTypes from 'prop-types';
import './autosuggest.scss';
import { mergeClasses } from '../../../libs/helpers';
import { Search as Icon, X as CloseIcon } from '../icons/icons';


const Autosuggest = (props) => {
  const {
    className, value, items, onSelected, emptyResult, onClear,
  } = props;

  const SEARCH_CLASS = 'search';
  const ROOT_CLASS = 'autosuggest';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const WRAP_CLASS = `${ROOT_CLASS}__wrap`;
  const SEARCH_WRAP_CLASS = `${SEARCH_CLASS}__wrap`;
  const wrapClass = mergeClasses(WRAP_CLASS, SEARCH_WRAP_CLASS);

  const ICON_CLASS = 'autosuggest__icon';
  const SEARCH_ICON_CLASS = `${SEARCH_CLASS}__icon`;
  const iconClassName = mergeClasses(ICON_CLASS, SEARCH_ICON_CLASS);

  const CLOSE_ICON_CLASS = 'autosuggest__close-icon';
  const SEARCH_CLOSE_ICON_CLASS = `${SEARCH_CLASS}__close-icon`;
  const closeIconClassName = mergeClasses(ICON_CLASS, CLOSE_ICON_CLASS, SEARCH_CLOSE_ICON_CLASS);

  const SEARCH_INPUT_CLASS = `${SEARCH_CLASS}__input`;
  const INPUT_CLASS = 'autosuggest__input';
  const inputClassName = mergeClasses(INPUT_CLASS, SEARCH_INPUT_CLASS);

  const SUGGESTIONS_CONTAINER = 'autosuggest__suggestions-container';
  const SUGGESTION_CLASS = 'autosuggest__suggestion';

  const theme = {
    container: 'autosuggest__container',
    containerOpen: 'autosuggest__container_open',
    input: inputClassName,
    inputOpen: `${INPUT_CLASS}_open`,
    inputFocused: `${INPUT_CLASS}_focused`,
    suggestionsContainer: SUGGESTIONS_CONTAINER,
    suggestionsContainerOpen: `${SUGGESTIONS_CONTAINER}_open`,
    suggestionsList: 'autosuggest__suggestions-list',
    suggestion: SUGGESTION_CLASS,
    suggestionFirst: `${SUGGESTION_CLASS}_first`,
    suggestionHighlighted: `${SUGGESTION_CLASS}_highlighted`,
    sectionContainer: 'autosuggest__section-container',
    sectionContainerFirst: 'autosuggest__section-container_first',
    sectionTitle: 'autosuggest__section-title',
  };


  const [suggestions, setSuggestions] = useState([]);
  const [valueSearch, setSearchValue] = useState(value);

  const handleChange = (event, { newValue }) => {
    setSearchValue(newValue);
  };

  const handleClear = () => {
    setSearchValue('');
    onClear();
  };

  // eslint-disable-next-line no-shadow
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    // eslint-disable-next-line no-shadow
    const suggestions = items.filter((item) => (
      item.name.toLowerCase().slice(0, inputLength) === inputValue
    ));

    const result = suggestions.length === 0 ? emptyResult : suggestions.slice(0, 5);
    return inputLength === 0 ? [] : result;
  };

  // eslint-disable-next-line no-shadow
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    onSelected({ id: suggestion.id });
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion, { query }) => (
    <div className={SUGGESTION_CLASS}>
      <Highlight search={query}>{suggestion.render || suggestion.name}</Highlight>
    </div>
  );

  const inputProps = {
    placeholder: 'Например, йоркширский терьер',
    value: valueSearch,
    onChange: handleChange,
  };

  // eslint-disable-next-line no-shadow,react/jsx-props-no-spreading
  const renderInputComponent = (inputProps) => <input {...inputProps} />;

  // eslint-disable-next-line react/prop-types
  const renderSuggestionsContainer = ({ containerProps, children }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...containerProps}>
      <div className="autosuggest__suggestions-container-inner">{children}</div>
    </div>
  );

  return (
    <div className={rootClass}>
      <div className={wrapClass}>
        <Icon className={iconClassName} />
        <ReactAutosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={renderSuggestionsContainer}
          theme={theme}
        />
        {valueSearch !== '' && <CloseIcon className={closeIconClassName} onClick={handleClear} role="button" />}
      </div>
    </div>

  );
};

Autosuggest.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  items: PropTypes.array.isRequired,
  onSelected: PropTypes.func,
  onClear: PropTypes.func,
  emptyResult: PropTypes.array,
};

Autosuggest.defaultProps = {
  className: '',
  value: '',
  onSelected: () => {
  },
  onClear: () => {
  },
  emptyResult: [],
};

export default Autosuggest;
