import React from 'react';
import PropTypes from 'prop-types';
import './offer-section.scss';
import { mergeClasses } from '../../../libs/helpers';

const OfferSection = (props) => {
  const {
    className, title, titleClass, description, button, buttonWrapClass, bodyClass, asideClass, asidePosition, children,
  } = props;

  const ROOT_CLASS = 'offer-section';
  const rootClass = mergeClasses(className, ROOT_CLASS);

  const ASIDE_CLASS = 'offer-section__aside';

  const BODY_CLASS = 'offer-section__body';
  const bodyClassName = mergeClasses(bodyClass, BODY_CLASS);

  const TITLE_CLASS = 'offer-section__title page__title page__title_h2';
  const titleClassName = mergeClasses(titleClass, TITLE_CLASS);

  const DESCRIPTION_CLASS = 'offer-section__description';

  const BUTTON_WRAP_CLASS = 'offer-section__button-wrap';
  const buttonWrapClassName = mergeClasses(buttonWrapClass, BUTTON_WRAP_CLASS);

  const BUTTON_WRAP_MOBILE_CLASS = mergeClasses(buttonWrapClassName, `${BUTTON_WRAP_CLASS}_mobile`);
  const BUTTON_WRAP_DESKTOP_CLASS = mergeClasses(buttonWrapClassName, `${BUTTON_WRAP_CLASS}_desktop`);

  const asideClassName = mergeClasses(ASIDE_CLASS, `${ASIDE_CLASS}_${asidePosition}`, asideClass);

  const Aside = (
    <div className={asideClassName}>
      {title && <h2 className={titleClassName}>{title}</h2>}
      {description && <p className={DESCRIPTION_CLASS}>{description}</p>}
      {button && <div className={BUTTON_WRAP_DESKTOP_CLASS}>{button}</div>}
    </div>
  );

  return (
    <div className={rootClass}>
      {asidePosition === 'left' && Aside}
      <div className={bodyClassName}>{children}</div>
      {asidePosition === 'right' && Aside}
      {button && <div className={BUTTON_WRAP_MOBILE_CLASS}>{button}</div>}
    </div>
  );
};

OfferSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.element,
  buttonWrapClass: PropTypes.string,
  bodyClass: PropTypes.string,
  asideClass: PropTypes.string,
  asidePosition: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
};

OfferSection.defaultProps = {
  className: '',
  title: '',
  titleClass: '',
  description: '',
  button: null,
  buttonWrapClass: '',
  bodyClass: '',
  asideClass: '',
  asidePosition: 'left',
  children: null,
};

export default OfferSection;
