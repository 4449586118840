import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';

const Price = (props) => {
  const { className, title, caption } = props;

  const ROOT_CLASS = 'service-table__price';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const PRICE_WRAP_CLASS = `${ROOT_CLASS}-wrap`;
  const PRICE_TITLE_CLASS = `${ROOT_CLASS}-title`;
  const PRICE_CAPTION_CLASS = `${ROOT_CLASS}-caption`;

  return (
    <td className={rootClass}>
      <div className={PRICE_WRAP_CLASS}>
        <div className={PRICE_TITLE_CLASS}>{title}</div>
        <div className={PRICE_CAPTION_CLASS}>{caption}</div>
      </div>
    </td>
  );
};

Price.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  caption: PropTypes.string,
};

Price.defaultProps = {
  className: '',
  title: '',
  caption: '',
};

export default Price;
