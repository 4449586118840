import React from 'react';
import PropTypes from 'prop-types';
import './offer-card.scss';
import { mergeClasses } from '../../../libs/helpers';

const OfferCard = (props) => {
  const {
    className, wrapClass, theme, title, titleClass, children,
  } = props;
  const ROOT_CLASS = 'offer-card';
  const THEME_CLASS = `${ROOT_CLASS}_${theme}`;
  const rootClass = mergeClasses(className, ROOT_CLASS, THEME_CLASS);

  const WRAP_CLASS = 'offer-card__wrap';
  const wrapClassName = mergeClasses(wrapClass, WRAP_CLASS);

  const TITLE_CLASS = 'offer-card__title';
  const titleClassName = mergeClasses(titleClass, TITLE_CLASS);

  return (
    <div className={rootClass}>
      <div className={wrapClassName}>
        {title && <h3 className={titleClassName}>{title}</h3>}
        {children}
      </div>
    </div>
  );
};

OfferCard.propTypes = {
  className: PropTypes.string,
  wrapClass: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  children: PropTypes.node,
};

OfferCard.defaultProps = {
  className: '',
  wrapClass: '',
  theme: 'yellow',
  title: '',
  titleClass: '',
  children: null,
};

export default OfferCard;
