import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './service-icon.scss';

const Icon = (props) => {
  const { className, href, bgColor } = props;

  const ROOT_CLASS = 'service-icon';
  const BG_COLOR_CLASS = bgColor ? `${ROOT_CLASS}_${bgColor}` : null;
  const rootClass = mergeClasses(ROOT_CLASS, BG_COLOR_CLASS, className);

  return (
    <span className={rootClass}>
      <svg>
        <use xlinkHref={`#${href}`} />
      </svg>
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  bgColor: PropTypes.oneOf(['yellow', 'orchid', 'red', 'purple-heart']),
};

Icon.defaultProps = {
  className: '',
  bgColor: 'yellow',
};

export default Icon;
